export const GAS_FOR_CLAIM = { SEFI: 1_000_000, ALTER: 1_000_000, OTHER: 1_000_000 };
export const PROPOSAL_BASE_FEE = 350_000;
export const GAS_FOR_EARN_DEPOSIT = { SEFI: 1_000_000, ALTER: 1_000_000, OTHER: 1_000_000 };
export const GAS_FOR_EARN_WITHDRAW = { SEFI: 1_000_000, ALTER: 1_000_000, OTHER: 1_000_000 };
export const GAS_FOR_CREATE_PAIR = 2_500_000;
export const GAS_FOR_PROVIDE = 1_000_000;
export const GAS_FOR_APPROVE = 200_000;
export const GAS_FOR_WITHDRAW_LP_FROM_SWAP = 1_200_000;
export const GAS_FOR_BASE_SWAP_ROUTE = 2_000_000;
export const GAS_FOR_SWAP_NATIVE_COIN = 500_000;
export const GAS_FOR_SWAP_DIRECT = 1_200_000;
export const GAS_FOR_VOTE = 250_000;
export const GAS_FOR_CREATE_VOTE = 250_000;
export const GAS_FOR_FINALIZE_VOTE = 250_000;
export const GAS_FOR_WRAP = 250_000;
