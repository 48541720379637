import * as React from 'react';

export const Password: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...svgProps }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9126 0.236689C12.0924 0.0587147 12.3457 -0.0261689 12.5977 0.00709579L15.0467 0.330328C15.4119 0.378535 15.6992 0.662971 15.7479 1.02452L16.0745 3.44876C16.1081 3.69825 16.0223 3.94899 15.8425 4.12696L14.7813 5.17747C14.5742 5.38252 14.2719 5.46198 13.9893 5.38567L13.5716 5.27291L13.6855 5.68638C13.7626 5.96615 13.6823 6.26535 13.4752 6.4704L12.6589 7.27848C12.4517 7.48353 12.1495 7.56299 11.8668 7.48669L11.4492 7.37392L11.5631 7.78739C11.6402 8.06715 11.5599 8.36636 11.3527 8.57141L9.97453 9.9357C10.338 11.5415 9.88756 13.2541 8.57723 14.5512C6.62578 16.4829 3.41503 16.4829 1.46359 14.5512C-0.487862 12.6195 -0.487862 9.44115 1.46359 7.50942C2.70634 6.27921 4.3572 5.85354 5.94606 6.14293L11.9126 0.236689ZM12.7818 1.66186L6.78131 7.60171C6.5732 7.80772 6.26917 7.88689 5.9856 7.80891C4.77637 7.47641 3.53615 7.74338 2.61805 8.65222C1.30419 9.9528 1.30419 12.1078 2.61805 13.4084C3.9319 14.709 6.10891 14.709 7.42277 13.4084C8.42589 12.4154 8.68003 11.1203 8.28679 9.95251C8.18901 9.66214 8.26536 9.34201 8.48399 9.12558L9.8633 7.7602L9.49815 6.43485C9.42107 6.15508 9.50134 5.85588 9.70848 5.65083C9.91563 5.44578 10.2179 5.36632 10.5005 5.44262L11.8394 5.80408L11.9857 5.65919L11.6206 4.33384C11.5435 4.05407 11.6238 3.75487 11.8309 3.54982C12.0381 3.34477 12.3403 3.26531 12.623 3.34161L13.9618 3.70307L14.4028 3.26653L14.2121 1.85064L12.7818 1.66186Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.48932 11.7979C4.62457 11.7979 4.73422 11.6894 4.73422 11.5555C4.73422 11.4216 4.62457 11.3131 4.48932 11.3131C4.35406 11.3131 4.24442 11.4216 4.24442 11.5555C4.24442 11.6894 4.35406 11.7979 4.48932 11.7979ZM3.10156 11.5555C3.10156 10.7968 3.72288 10.1818 4.48932 10.1818C5.25575 10.1818 5.87707 10.7968 5.87707 11.5555C5.87707 12.3142 5.25575 12.9292 4.48932 12.9292C3.72288 12.9292 3.10156 12.3142 3.10156 11.5555Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
