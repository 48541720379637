import * as React from 'react';

export const Filter2: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...svgProps }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <g opacity="0.7" fill="none">
      <rect x="1.74219" y="3" width="13.0005" height="1.4287" rx="0.714348" fill="currentColor" />
      <rect x="2.74219" y="7.28613" width="10.29" height="1.4287" rx="0.714348" fill="currentColor" />
      <rect x="5.74219" y="11.5713" width="4.3335" height="1.4287" rx="0.714348" fill="currentColor" />
    </g>
  </svg>
);
