// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ZrvDh5ySaIL-KG85CWizA\\=\\={animation-duration:25s;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-name:fo9TqwxTHucXOHpsVooWAQ\\=\\=;animation-timing-function:linear;background:fff;background:linear-gradient(to right,#ebf3f9 40%,#fff 60%,#ebf3f9 60%);height:14px;position:absolute;-webkit-backface-visibility:hidden;left:20%;top:50%;margin-top:-7px;min-height:14px;width:50%}@-moz-keyframes fo9TqwxTHucXOHpsVooWAQ\\=\\={0%{background-position:0 0}100%{background-position:100em 0}}@-webkit-keyframes fo9TqwxTHucXOHpsVooWAQ\\=\\={0%{background-position:0 0}100%{background-position:100em 0}}@-o-keyframes fo9TqwxTHucXOHpsVooWAQ\\=\\={0%{background-position:0 0}100%{background-position:100em 0}}@keyframes fo9TqwxTHucXOHpsVooWAQ\\=\\={0%{background-position:0 0}100%{background-position:100em 0}}", "",{"version":3,"sources":["webpack://src/ui/Spinner/SpinnerLine.styl"],"names":[],"mappings":"AASA,6BACE,sBAAoB,CACpB,4BAAqB,CACrB,kCAA2B,CAC3B,yCAAgB,CAChB,gCAA2B,CAC3B,cAAY,CACZ,qEAAY,CACZ,WAAQ,CACR,iBAAU,CACV,kCAA6B,CAC7B,QAAM,CACN,OAAK,CACL,eAAY,CACZ,eAAY,CACZ,SAAO,CAxBT,2CACE,GACE,uBAAqB,CAEvB,KACE,2BAAqB,CAAA,CALzB,8CACE,GACE,uBAAqB,CAEvB,KACE,2BAAqB,CAAA,CALzB,yCACE,GACE,uBAAqB,CAEvB,KACE,2BAAqB,CAAA,CALzB,sCACE,GACE,uBAAqB,CAEvB,KACE,2BAAqB,CAAA","sourcesContent":["@keyframes placeHolderShimmer {\n  0% {\n    background-position: 0px 0;\n  }\n  100% {\n    background-position: 100em 0;\n  }\n}\n\n.spinnerLine {\n  animation-duration: 25s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: placeHolderShimmer;\n  animation-timing-function: linear;\n  background: fff;\n  background: linear-gradient(to right, #ebf3f9 40%, #ffffff 60%, #ebf3f9 60%);\n  height: 14px;\n  position: absolute;\n  -webkit-backface-visibility: hidden;\n  left: 20%;\n  top: 50%;\n  margin-top: -7px;\n  min-height: 14px;\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerLine": "_2ZrvDh5ySaIL-KG85CWizA==",
	"placeHolderShimmer": "fo9TqwxTHucXOHpsVooWAQ=="
};
export default ___CSS_LOADER_EXPORT___;
