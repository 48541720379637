// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X2UhASlMX21MoTKRKvOQfw\\=\\= ul{list-style:none;padding-left:20px}.X2UhASlMX21MoTKRKvOQfw\\=\\= ul li{margin:7px 0}.X2UhASlMX21MoTKRKvOQfw\\=\\= span{color:#47b8eb}.X2UhASlMX21MoTKRKvOQfw\\=\\= a{color:#47b8eb;text-decoration-color:#47b8eb}", "",{"version":3,"sources":["webpack://src/components/info-styles.styl"],"names":[],"mappings":"AACE,+BACE,eAAW,CACX,iBAAa,CAEb,kCACE,YAAO,CAIX,iCACE,aAAO,CAGT,8BACE,aAAM,CACN,6BAAsB","sourcesContent":[".infoContainer {\n  ul {\n    list-style none\n    padding-left 20px\n\n    li {\n      margin 7px 0\n    }\n  }\n\n  span {\n    color: #47b8eb\n  }\n\n  a {\n    color #47b8eb\n    text-decoration-color #47b8eb\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoContainer": "X2UhASlMX21MoTKRKvOQfw=="
};
export default ___CSS_LOADER_EXPORT___;
