// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+cyEbCmCzOEE6LAry44myg\\=\\={width:64px;height:64px;position:relative;animation:KWO-zvE\\+kKae9pGSRnGdew\\=\\= 1.6s infinite}.\\+cyEbCmCzOEE6LAry44myg\\=\\=:after{display:block;content:'';position:absolute;background:url(\"https://s2.coinmarketcap.com/static/img/coins/64x64/5604.png\");background-size:cover;width:40px;height:40px;top:9px;left:10px}@-moz-keyframes KWO-zvE\\+kKae9pGSRnGdew\\=\\={from{transform:rotate(360deg)}to{transform:rotate(0deg)}}@-webkit-keyframes KWO-zvE\\+kKae9pGSRnGdew\\=\\={from{transform:rotate(360deg)}to{transform:rotate(0deg)}}@-o-keyframes KWO-zvE\\+kKae9pGSRnGdew\\=\\={from{transform:rotate(360deg)}to{transform:rotate(0deg)}}@keyframes KWO-zvE\\+kKae9pGSRnGdew\\=\\={from{transform:rotate(360deg)}to{transform:rotate(0deg)}}", "",{"version":3,"sources":["webpack://src/ui/Spinner/Spinner.styl"],"names":[],"mappings":"AAUA,6BAEC,UAAO,CACP,WAAQ,CACR,iBAAU,CACV,mDAAW,CAEX,mCACC,aAAS,CACT,UAAS,CACT,iBAAU,CACV,8EAA8E,CAC9E,qBAAiB,CACjB,UAAO,CACP,WAAQ,CACR,OAAK,CACL,SAAM,CA1BR,4CACC,KACC,wBAAW,CAGZ,GACC,sBAAW,CAAA,CANb,+CACC,KACC,wBAAW,CAGZ,GACC,sBAAW,CAAA,CANb,0CACC,KACC,wBAAW,CAGZ,GACC,sBAAW,CAAA,CANb,uCACC,KACC,wBAAW,CAGZ,GACC,sBAAW,CAAA","sourcesContent":["@keyframes spin {\n\tfrom {\n\t\ttransform: rotate(360deg);\n\t}\n\n\tto {\n\t\ttransform: rotate(0deg);\n\t}\n}\n\n.spinnerContainer {\n\t// display: block;\n\twidth: 64px;\n\theight: 64px;\n\tposition: relative;\n\tanimation: spin 1.6s infinite;\n\n\t&:after {\n\t\tdisplay: block;\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\tbackground: url('https://s2.coinmarketcap.com/static/img/coins/64x64/5604.png');\n\t\tbackground-size: cover;\n\t\twidth: 40px;\n\t\theight: 40px;\n\t\ttop: 9px;\n\t\tleft: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": "+cyEbCmCzOEE6LAry44myg==",
	"spin": "KWO-zvE+kKae9pGSRnGdew=="
};
export default ___CSS_LOADER_EXPORT___;
