// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".boDzO2hQdae9CTj96cR0nw\\=\\={font-family:Helvetica,sans-serif}.boDzO2hQdae9CTj96cR0nw\\=\\= .m99DSkTalV-xxRTDCyu56Q\\=\\={font-size:16px;line-height:28px;letter-spacing:normal;margin:0;user-select:none}.RqaDh6wMfjhdZg-3ym2KbQ\\=\\={border-top:1px solid #e6e6e6;padding-top:30px;margin-top:30px}.RqaDh6wMfjhdZg-3ym2KbQ\\=\\=:first-child{padding-top:0;border-top:0}.RqaDh6wMfjhdZg-3ym2KbQ\\=\\=:last-child{margin-bottom:30px}", "",{"version":3,"sources":["webpack://src/components/Base/components/Collapse/styles.styl"],"names":[],"mappings":"AAGA,4BACE,gCAAa,CACb,wDACE,cAAW,CACX,gBAAa,CACb,qBAAgB,CAChB,QAAQ,CACR,gBAAa,CAIjB,4BACE,4BAAY,CACZ,gBAAa,CACb,eAAY,CAGd,wCACE,aAAa,CACb,YAAY,CAGd,uCACE,kBAAe","sourcesContent":["Basic300 = #E6E6E6;\n$fontFamily = Helvetica, sans-serif;\n\n.collapse {\n  font-family: $fontFamily;\n  .collapseHeader {\n    font-size: 16px;\n    line-height: 28px;\n    letter-spacing: normal;\n    margin: 0;\n    user-select: none;\n  }\n}\n\n.collapseBordered {\n  border-top: 1px solid Basic300;\n  padding-top: 30px;\n  margin-top: 30px;\n}\n\n.collapseBordered:first-child {\n  padding-top: 0;\n  border-top: 0;\n}\n\n.collapseBordered:last-child {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": "boDzO2hQdae9CTj96cR0nw==",
	"collapseHeader": "m99DSkTalV-xxRTDCyu56Q==",
	"collapseBordered": "RqaDh6wMfjhdZg-3ym2KbQ=="
};
export default ___CSS_LOADER_EXPORT___;
