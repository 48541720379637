// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EuN0Phy-kSXIsufGgn3ZcA\\=\\={cursor:pointer;opacity:.6}.EuN0Phy-kSXIsufGgn3ZcA\\=\\=:hover{opacity:1}", "",{"version":3,"sources":["webpack://src/components/Base/components/Icons/styles.styl"],"names":[],"mappings":"AAAA,4BACE,cAAO,CACP,UAAS,CACT,kCACE,SAAS","sourcesContent":[".hoverIcon {\n  cursor pointer\n  opacity: 0.6\n  &:hover {\n    opacity: 1\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverIcon": "EuN0Phy-kSXIsufGgn3ZcA=="
};
export default ___CSS_LOADER_EXPORT___;
